import React from 'react'
import TrainingTemplate from 'components/templates/TrainingTemplate/TrainingTemplate'

export default function TrainingFirstAidPage() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const productName = 'Primo Soccorso'

  const description =
    'Il corso fornisce la formazione obbligatoria prevista <b>D. Lgs. 81/08.</b><br> Si prevede una parte teorica e una pratica, per l‘attuazione delle misure di primo intervento interno e per l’attivazione degli interventi di pronto soccorso.<br><br> Il programma formativo, definito dal DM 388 del 15 Luglio 2003, si sviluppa su 3 moduli e ha durata variabile in base alla tipologia dell’azienda:<br> – Aziende del Gruppo A : 16 ore<br> – Aziende del Gruppo B o C : 12 ore<br><br> Periodicità aggiornamento: 3 anni (come previsto dal DM 388/03).<br> – Aziende del Gruppo A : 6 ore<br> – Aziende del Gruppo B o C : 4 ore<br><br> <b>ING GROUP</b> organizza tali corsi presso le proprie sedi o, a richiesta, presso l’azienda.'

  const description1 = null

  return (
    <>
      <TrainingTemplate
        seoKeywords={seoKeywords}
        productName={productName}
        description={description}
        description1={description1}
      />
    </>
  )
}
